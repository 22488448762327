import React from "react";
import Navbar from "./Navbar";
import { Outlet, useLocation } from "react-router-dom";
import Footer from "./Footer";

const MainLayout = () => {
  const location = useLocation();
  console.log(location);

  return (
    <>
      <Navbar lightNav={location?.pathname.includes("about")} />
      <>
        <Outlet />
      </>
      <Footer />
    </>
  );
};

export default MainLayout;
