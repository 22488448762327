import MainLayout from "../components/MainLayout";
import About from "../pages/About";
import Home from "../pages/Home";

const routes = [
  {
    element: <MainLayout />,
    children: [
      {
        path: "/",
        index: true,
        element: <Home />,
      },
      {
        path: "/about",
        index: true,
        element: <About />,
      },
    ],
  },
];

export default routes;
