import React from "react";
import { AiFillApple } from "react-icons/ai";
import PropTypes from "prop-types";

const StoreButton = ({ alternate, link, icon }) => {
  return (
    <button className="flex items-center justify-between bg-white rounded-xl px-3 gap-1 py-3">
      <div>
        <AiFillApple fontSize={"20px"} />
      </div>
      <p className="text-sm text-black font-medium">
        Available on {`${alternate ? "App store" : "Playstore"}`}
      </p>
    </button>
  );
};

StoreButton.propTypes = {
  alternate: PropTypes.bool.isRequired,
  link: PropTypes.string.isRequired,
};

export default StoreButton;
