import {
  BrowserRouter,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import routes from "./routes/route";
import Navbar from "./components/Navbar";
import { ChakraProvider } from "@chakra-ui/react";

const router = createBrowserRouter(routes);
function App() {
  return (
    <ChakraProvider>
      <RouterProvider router={router} />
    </ChakraProvider>
  );
}

export default App;
