import React from "react";

const TwitterIcon = () => {
  return (
    <svg
      width="24"
      height="22"
      viewBox="0 0 24 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="X svg" clip-path="url(#clip0_29_2481)">
        <g id="Group">
          <path
            id="Vector"
            d="M18.8916 0.156616H22.5542L14.5542 9.36144L24 21.8434H16.5783L10.7952 14.2771L4.14458 21.8434H0.481928L9.06024 12.012L0 0.156616H7.61446L12.8675 7.09638L18.8916 0.156616ZM17.5904 19.6265H19.6145L6.50602 2.22891H4.28916L17.5904 19.6265Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_29_2481">
          <rect
            width="24"
            height="21.6867"
            fill="white"
            transform="translate(0 0.156616)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default TwitterIcon;
