import React from "react";
import StoreButton from "./StoreButton";
import bannerImg from "../assets/Free Hand Holding iPhone 12 Mini 1.png";

const Banner = () => {
  return (
    <div className="max-w-[1580px] px-1 sm:px-10 md:px-20 mx-auto">
      <div className="bg-black rounded-[36px] flex px-5 banner py-5 md:py-0">
        <div className="hidden md:block mx-auto  banner-img pt-5">
          <img src={bannerImg} alt="" />
        </div>
        <div className="md:w-1/2 flex flex-col justify-center banner-content gap-5 text-center md:text-left">
          <p className="text-white text-4xl mb-5">
            Join the Crypto Revolution. Download and get started!
          </p>
          <div className="flex items-center justify-center md:justify-start gap-3">
            <StoreButton alternate />
            <StoreButton />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
