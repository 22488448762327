import React from "react";
import BrandLogo from "./BrandLogo";
import { NavLink } from "react-router-dom";
import TwitterIcon from "../icons/TwitterIcon";
import InstaIcon from "./InstaIcon";
import FacebookIcon from "../icons/FacebookIcon";

const Footer = () => {
  return (
    <footer className="footer flex items-center gap-3 justify-between bg-black py-10 text-white px-1 sm:px-10 md:px-16">
      <div>
        <BrandLogo />
      </div>
      <div className=" flex flex-wrap gap-5 text-sm sm:text-base w-fit">
        <div className="cursor-pointer">
          <NavLink to="/about" className="font-medium">
            About
          </NavLink>
        </div>
        <div className="cursor-pointer">
          <NavLink to="" className="font-medium">
            Contact us
          </NavLink>
        </div>
      </div>
      <div className="flex flex-wrap items-center gap-3">
        <a href="#" target="_blank">
          <InstaIcon />
        </a>
        <a href="#" target="_blank">
          <FacebookIcon />
        </a>
        <a href="#" target="_blank">
          <TwitterIcon />
        </a>
        <a href="#" target="_blank">
          <img src="" alt="" />
        </a>
      </div>
    </footer>
  );
};

export default Footer;
