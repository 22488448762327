import React from "react";
import StoreButton from "../components/StoreButton";
import Banner from "../components/Banner";
import heroImg from "../assets/iPhone 14 Pro Mockup 1.png";
import Card from "../components/Card";
import spendImg from "../assets/image container.png";
import sendImg from "../assets/image container-2.png";
import WalletIcon from "../icons/WalletIcon";
import ReceiptIcon from "../icons/ReceiptIcon";
import DollarIcon from "../icons/DollarIcon";
import FaqSection from "../components/FaqSection";

const Home = () => {
  return (
    <div>
      <section className="bg-black hero py-5 px-10">
        <div className="hero-section md:flex justify-between px-5">
          <div className="hero-section_text text-center md:text-left flex flex-col justify-center gap-5 md:gap-10 md:w-2/3 text-[30px] sm:text-[32px] md:text-[48px]">
            <h4>
              Revolutionize Your <br />
              Payments.
            </h4>
            <p className="text-white text-[20px] sm:text-[32px]">
              Make payments with your crypto assets anytime, anyhwhere.
            </p>
            <div className="flex items-center justify-center md:justify-start gap-3">
              <StoreButton alternate />
              <StoreButton />
            </div>
          </div>
          <div className="hero-section_img w-1/2 hidden md:block">
            <img src={heroImg} alt="" />
          </div>
        </div>
      </section>
      <section className="py-20 container">
        <h2 className="text-3xl font-semibold mb-10 text-center md:text-left">
          Take control of your Crypto Assets
        </h2>
        <div className="flex flex-wrap  gap-10">
          <div className="md:w-[45%]">
            <Card
              title="Spend"
              content="Stantpay gives you more than one way to easily pay your bills with your crypto assets"
              img={spendImg}
            />
          </div>
          <div className="md:w-[45%]">
            <Card
              title="Send"
              img={sendImg}
              content="Make unlimited transfers with multiple options such as Payment link, NFC and Stant user tag."
            />
          </div>
        </div>
      </section>
      <section className="container !mb-20">
        <h2 className="text-3xl font-semibold mb-10">All you will ever need</h2>
        <div className="flex gap-5 flex-wrap">
          <div className="my-3 md:my-0 md:w-[32%]">
            <WalletIcon />
            <div>
              <h2 className="text-xl font-medium">Top-up</h2>
              <p>
                Make direct transfers from your crypto wallet to your stantpay
                account and get credited instantly
              </p>
            </div>
          </div>
          <div className="my-3 lg:my-0 md:w-[32%]">
            <ReceiptIcon />
            <div>
              <h2 className="text-xl font-medium">Pay Bills</h2>
              <p>
                Choose from a variety of options to send money to friends,
                family and associates.
              </p>
            </div>
          </div>
          <div className="my-3 lg:my-0 md:w-[32%]">
            <DollarIcon />
            <div>
              <h2 className="text-xl font-medium">
                International Transactions
              </h2>
              <p>
                Create a virtual dollar card, top-up and spend your assets
                worldwide.
              </p>
            </div>
          </div>
        </div>
      </section>

      <Banner />

      <FaqSection />
    </div>
  );
};

export default Home;
