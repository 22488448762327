import React from "react";

const WalletIcon = () => {
  return (
    <div>
      <svg
        width="64"
        height="64"
        viewBox="0 0 64 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="32" cy="32" r="32" fill="#ECFDF3" />
        <path
          d="M39.6532 46.3333H26.1732C25.7466 46.3333 25.3732 46.0667 25.2266 45.68C25.0799 45.28 25.1999 44.84 25.5199 44.5733C25.8399 44.3067 26.1333 43.96 26.3466 43.5867C26.7733 42.9067 26.9866 42.1333 26.9866 41.3467C26.9866 38.96 25.0399 37.0133 22.6532 37.0133C21.6666 37.0133 20.7333 37.3466 19.9466 37.9733C19.6399 38.2133 19.2399 38.2533 18.8932 38.0933C18.5466 37.9333 18.3333 37.5734 18.3333 37.1867V31.36C18.3333 28.04 21.0266 25.3467 24.3466 25.3467H39.6532C42.9732 25.3467 45.6666 28.04 45.6666 31.36V33.28C45.6666 33.8267 45.2133 34.28 44.6666 34.28H41.9733C41.5066 34.28 41.0799 34.4534 40.7733 34.7734L40.7599 34.7867C40.3866 35.1467 40.2133 35.64 40.2533 36.1466C40.3333 37.0266 41.1733 37.7333 42.1333 37.7333H44.6666C45.2133 37.7333 45.6666 38.1867 45.6666 38.7333V40.32C45.6666 43.64 42.9732 46.3333 39.6532 46.3333ZM28.2399 44.3333H39.6532C41.8666 44.3333 43.6666 42.5333 43.6666 40.32V39.7333H42.1333C40.1199 39.7333 38.4133 38.24 38.2533 36.32C38.1466 35.2267 38.5466 34.1467 39.3466 33.36C40.0399 32.6534 40.9733 32.2667 41.9733 32.2667H43.6666V31.3467C43.6666 29.1333 41.8666 27.3333 39.6532 27.3333H24.3466C22.1333 27.3333 20.3333 29.1333 20.3333 31.3467V35.4534C21.0799 35.16 21.8666 35 22.6666 35C26.1599 35 28.9999 37.84 28.9999 41.3333C28.9999 42.3867 28.7333 43.4267 28.2399 44.3333Z"
          fill="#027A48"
        />
        <path
          d="M19.3333 33.5465C18.7866 33.5465 18.3333 33.0932 18.3333 32.5465V26.4533C18.3333 24.4666 19.5866 22.6665 21.4399 21.9598L32.0266 17.9598C33.1066 17.5465 34.3333 17.6932 35.2799 18.3599C36.2399 19.0266 36.7999 20.1066 36.7999 21.2666V26.3332C36.7999 26.8799 36.3466 27.3332 35.7999 27.3332C35.2533 27.3332 34.7999 26.8799 34.7999 26.3332V21.2666C34.7999 20.7599 34.5599 20.2932 34.1333 19.9999C33.7066 19.7065 33.1999 19.6399 32.7199 19.8265L22.1332 23.8265C21.0533 24.2399 20.3199 25.2933 20.3199 26.4533V32.5465C20.3332 33.1065 19.8799 33.5465 19.3333 33.5465Z"
          fill="#027A48"
        />
        <path
          d="M42.1333 39.7332C40.12 39.7332 38.4133 38.2399 38.2533 36.3199C38.1466 35.2132 38.5466 34.1333 39.3466 33.3466C40.0266 32.6533 40.96 32.2666 41.96 32.2666H44.7333C46.0533 32.3066 47.0666 33.3465 47.0666 34.6265V37.3733C47.0666 38.6533 46.0533 39.6932 44.7733 39.7332H42.1333ZM44.7066 34.2666H41.9733C41.5066 34.2666 41.08 34.4399 40.7733 34.7599C40.3866 35.1333 40.2 35.6399 40.2533 36.1466C40.3333 37.0266 41.1733 37.7332 42.1333 37.7332H44.7466C44.92 37.7332 45.08 37.5733 45.08 37.3733V34.6265C45.08 34.4265 44.92 34.2799 44.7066 34.2666Z"
          fill="#027A48"
        />
        <path
          d="M34.6666 33H25.3333C24.7866 33 24.3333 32.5467 24.3333 32C24.3333 31.4533 24.7866 31 25.3333 31H34.6666C35.2133 31 35.6666 31.4533 35.6666 32C35.6666 32.5467 35.2133 33 34.6666 33Z"
          fill="#027A48"
        />
        <path
          d="M22.6666 47.6667C20.4533 47.6667 18.3733 46.4933 17.2533 44.5867C16.6533 43.6267 16.3333 42.4933 16.3333 41.3333C16.3333 39.3867 17.1999 37.5866 18.7066 36.3866C19.8266 35.4933 21.2399 35 22.6666 35C26.1599 35 28.9999 37.84 28.9999 41.3333C28.9999 42.4933 28.6799 43.6267 28.0799 44.6C27.7599 45.16 27.3199 45.6667 26.8133 46.0933C25.7066 47.1067 24.2266 47.6667 22.6666 47.6667ZM22.6666 37C21.6799 37 20.7466 37.3333 19.9599 37.96C18.9333 38.7733 18.3333 40.0133 18.3333 41.3333C18.3333 42.12 18.5466 42.8933 18.9599 43.56C19.7466 44.8933 21.1333 45.6667 22.6666 45.6667C23.7199 45.6667 24.7332 45.28 25.5066 44.5867C25.8532 44.2933 26.1466 43.9467 26.3599 43.5733C26.7866 42.8933 26.9999 42.12 26.9999 41.3333C26.9999 38.9467 25.0533 37 22.6666 37Z"
          fill="#027A48"
        />
        <path
          d="M24.6534 42.3066H20.6667C20.1201 42.3066 19.6667 41.8533 19.6667 41.3066C19.6667 40.76 20.1201 40.3066 20.6667 40.3066H24.6534C25.2001 40.3066 25.6534 40.76 25.6534 41.3066C25.6534 41.8533 25.2134 42.3066 24.6534 42.3066Z"
          fill="#027A48"
        />
        <path
          d="M22.6667 44.3465C22.1201 44.3465 21.6667 43.8932 21.6667 43.3465V39.3599C21.6667 38.8132 22.1201 38.3599 22.6667 38.3599C23.2134 38.3599 23.6667 38.8132 23.6667 39.3599V43.3465C23.6667 43.9065 23.2134 44.3465 22.6667 44.3465Z"
          fill="#027A48"
        />
      </svg>
    </div>
  );
};

export default WalletIcon;
