import React from "react";

const ReceiptIcon = () => {
  return (
    <div>
      <svg
        width="64"
        height="64"
        viewBox="0 0 64 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="32" cy="32" r="32" fill="#FFF6ED" />
        <rect
          width="32"
          height="32"
          transform="translate(16 16)"
          fill="#FFF6ED"
        />
        <path
          d="M41.8934 31.6665H37.3334C36.7867 31.6665 36.3334 31.2132 36.3334 30.6665V21.3465C36.3334 20.3598 36.72 19.4398 37.4134 18.7465C38.1067 18.0532 39.0267 17.6665 40.0134 17.6665H40.0267C41.6934 17.6798 43.2667 18.3332 44.4667 19.5198C45.6667 20.7332 46.32 22.3332 46.32 23.9998V27.2265C46.3334 29.8798 44.5467 31.6665 41.8934 31.6665ZM38.3334 29.6665H41.8934C43.44 29.6665 44.3334 28.7732 44.3334 27.2265V23.9998C44.3334 22.8532 43.88 21.7598 43.0667 20.9332C42.2534 20.1332 41.16 19.6798 40.0267 19.6665C40.0267 19.6665 40.0267 19.6665 40.0134 19.6665C39.5734 19.6665 39.1467 19.8398 38.8267 20.1598C38.5067 20.4798 38.3334 20.8932 38.3334 21.3465V29.6665Z"
          fill="#C4320A"
        />
        <path
          d="M28 47.1065C27.3733 47.1065 26.7866 46.8665 26.3466 46.4132L24.1333 44.1865C24.0133 44.0665 23.8266 44.0532 23.6933 44.1598L21.4 45.8665C20.6933 46.3998 19.76 46.4932 18.96 46.0932C18.16 45.6932 17.6666 44.8932 17.6666 43.9998V23.9998C17.6666 19.9732 19.9733 17.6665 24 17.6665H40C40.5466 17.6665 41 18.1198 41 18.6665C41 19.2132 40.5466 19.6665 40 19.6665C39.08 19.6665 38.3333 20.4132 38.3333 21.3332V43.9998C38.3333 44.8932 37.84 45.6932 37.04 46.0932C36.24 46.4932 35.3066 46.4132 34.6 45.8798L32.32 44.1732C32.1866 44.0665 32 44.0932 31.8933 44.1998L29.6533 46.4398C29.2133 46.8665 28.6266 47.1065 28 47.1065ZM23.88 42.0932C24.4933 42.0932 25.0933 42.3198 25.5466 42.7865L27.76 45.0132C27.84 45.0932 27.9466 45.1065 28 45.1065C28.0533 45.1065 28.16 45.0932 28.24 45.0132L30.48 42.7732C31.3066 41.9465 32.6133 41.8665 33.5333 42.5732L35.8 44.2665C35.9466 44.3732 36.08 44.3332 36.1466 44.2932C36.2133 44.2532 36.3333 44.1732 36.3333 43.9998V21.3332C36.3333 20.7332 36.48 20.1598 36.7333 19.6665H24C21.04 19.6665 19.6666 21.0398 19.6666 23.9998V43.9998C19.6666 44.1865 19.7866 44.2665 19.8533 44.3065C19.9333 44.3465 20.0666 44.3732 20.2 44.2665L22.48 42.5598C22.8933 42.2532 23.3866 42.0932 23.88 42.0932Z"
          fill="#C4320A"
        />
        <path
          d="M32 34.3467H28C27.4533 34.3467 27 33.8933 27 33.3467C27 32.8 27.4533 32.3467 28 32.3467H32C32.5467 32.3467 33 32.8 33 33.3467C33 33.8933 32.5467 34.3467 32 34.3467Z"
          fill="#C4320A"
        />
        <path
          d="M32 29.0132H28C27.4533 29.0132 27 28.5599 27 28.0132C27 27.4665 27.4533 27.0132 28 27.0132H32C32.5467 27.0132 33 27.4665 33 28.0132C33 28.5599 32.5467 29.0132 32 29.0132Z"
          fill="#C4320A"
        />
        <path
          d="M23.9603 29.3468C23.227 29.3468 22.627 28.7468 22.627 28.0135C22.627 27.2802 23.227 26.6802 23.9603 26.6802C24.6936 26.6802 25.2936 27.2802 25.2936 28.0135C25.2936 28.7468 24.6936 29.3468 23.9603 29.3468Z"
          fill="#C4320A"
        />
        <path
          d="M23.9603 34.6799C23.227 34.6799 22.627 34.0799 22.627 33.3465C22.627 32.6132 23.227 32.0132 23.9603 32.0132C24.6936 32.0132 25.2936 32.6132 25.2936 33.3465C25.2936 34.0799 24.6936 34.6799 23.9603 34.6799Z"
          fill="#C4320A"
        />
      </svg>
    </div>
  );
};

export default ReceiptIcon;
