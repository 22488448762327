import React from "react";
import PropTypes from "prop-types";

const Card = ({ img, title, content }) => {
  return (
    <section className="card p-4 border rounded-3xl">
      <div className="card-img">
        <img src={img} alt="" />
      </div>
      <div className="card-content">
        <h2 className="text-2xl font-semibold">{title}</h2>
        <p>{content}</p>
      </div>
    </section>
  );
};

Card.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  img: PropTypes.element.isRequired,
};

export default Card;
