import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
} from "@chakra-ui/react";
import React from "react";

const faqQuestions = [
  {
    id: "1",
    question: "How to use the app",
    answer:
      "Simply click on our app icon on your mobile device to launch it, enter your login details if you are already signed up, or register in one easy step to start enjoying our service.",
  },
  {
    id: "2",
    question: "What is NFC technology?",
    answer:
      "NFC (Near Field Communication) is a wireless technology that enables short-range communication between devices. In the context of our fintech platform, NFC technology allows for fast and secure payment transactions by simply tapping your phone on a merchant's terminal device.",
  },
  {
    id: "3",
    question: "How does QRCode work in your platform?",
    answer:
      "Our platform allows for quick and convenient payments using QRCode technology. To make a payment, simply scan the code displayed on the merchant's terminal using your smartphone. The payment will be processed instantly and securely.",
  },
  {
    id: "4",
    question: "What is User Tag and how does it work in your fintech platform?",
    answer:
      "A user tag is a unique identifier that you can use to send and receive payments from other users within our platform. This allows you to quickly and easily transfer funds to friends, family, and other contacts without having to enter their payment information manually.",
  },
  {
    id: "5",
    question:
      "What is Social Link Payment and how can I use it  with StantPay?",
    answer:
      "Social Link Payment is a feature that allows you to make payments directly from your account. Simply put, some funds are in a link that is randomly generated along with a claim password within our platform, and the funds sit in the link until they are claimed.",
  },
  {
    id: "6",
    question: "Does your platform support cryptocurrency transactions?",
    answer:
      "Yes, our platform supports cryptocurrency transactions. You can use popular cryptocurrencies such as Bitcoin and Ethereum to make fast and secure transactions with low fees.",
  },
  {
    id: "7",
    question: "Is my financial information secure on StantPay?",
    answer:
      "We take the security of our users' financial information very seriously. Our platform is protected by advanced security measures to ensure that your information is always safe. ",
  },
  {
    id: "8",
    question: "How can I add funds to my account on your platform?",
    answer:
      "You can add funds to your account through various methods, including NFC, QRCODE, USERTAG, and Social Link Deposit. Our platform is designed to be flexible and convenient, making it easy for you to manage your finances.",
  },
  {
    id: "9",
    question:
      "Can I use NFC and QRCode technology to make payments internationally?",
    answer:
      " Currently, our NFC and QRCode payment services are limited to certain countries. However, our cryptocurrency support provides you with a fast and secure way to make international transactions.",
  },
  {
    id: "10",
    question: "Are there any fees for using your platform?",
    answer:
      "Our platform has a transparent fee structure, with low fees for transactions and other services. You can view our fee schedule on our website or in the app.",
  },
  {
    id: "11",
    question: "Will I still have access to my account if I delete the app?",
    answer:
      "Yes, you can always access your email at any time, anywhere, with your mobile device as long as you remember your email address.",
  },
  {
    id: "12",
    question: "Can I withdraw to my local banks?",
    answer: "Yes, you can always withdraw at any local bank of your choice.",
  },
];

const FaqSection = () => {
  return (
    <section
      className="py-32 max-w-[1580px] sm:px-10 md:px-20 mx-auto faq"
      id="faq"
    >
      <div className="mb-10 text-center">
        <h2 className="text-3xl font-semibold">FAQs</h2>
        <p>Questions? We got answers</p>
      </div>
      <div className="flex gap-5 faq-card w-full lg:w-[70%]">
        <Accordion width={"100%"} allowToggle>
          {faqQuestions.map((data) => (
            <AccordionItem key={data.id}>
              {({ isExpanded }) => (
                <>
                  <h2>
                    <AccordionButton>
                      <Box
                        as="span"
                        flex="1"
                        textAlign="left"
                        fontSize={18}
                        fontWeight={500}
                      >
                        {data.question}
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>{data.answer}</AccordionPanel>
                </>
              )}
            </AccordionItem>
          ))}
        </Accordion>
      </div>
    </section>
  );
};

export default FaqSection;
