import React from "react";

const DollarIcon = () => {
  return (
    <div>
      <svg
        width="64"
        height="64"
        viewBox="0 0 64 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="32" cy="32" r="32" fill="#EFF8FF" />
        <path
          d="M33.8666 39.2268H30.52C28.3333 39.2268 26.56 37.3868 26.56 35.1201C26.56 34.5734 27.0133 34.1201 27.56 34.1201C28.1066 34.1201 28.56 34.5734 28.56 35.1201C28.56 36.2801 29.44 37.2268 30.52 37.2268H33.8666C34.7333 37.2268 35.4533 36.4534 35.4533 35.5201C35.4533 34.3601 35.04 34.1334 34.36 33.8934L28.9866 32.0001C27.9466 31.6401 26.5466 30.8668 26.5466 28.4801C26.5466 26.4268 28.16 24.7734 30.1333 24.7734H33.48C35.6666 24.7734 37.44 26.6134 37.44 28.8801C37.44 29.4268 36.9866 29.8801 36.44 29.8801C35.8933 29.8801 35.44 29.4268 35.44 28.8801C35.44 27.7201 34.56 26.7734 33.48 26.7734H30.1333C29.2666 26.7734 28.5466 27.5468 28.5466 28.4801C28.5466 29.6401 28.96 29.8668 29.64 30.1068L35.0133 32.0001C36.0533 32.3601 37.4533 33.1334 37.4533 35.5201C37.44 37.5601 35.84 39.2268 33.8666 39.2268Z"
          fill="#175CD3"
        />
        <path
          d="M32 41C31.4533 41 31 40.5467 31 40V24C31 23.4533 31.4533 23 32 23C32.5467 23 33 23.4533 33 24V40C33 40.5467 32.5467 41 32 41Z"
          fill="#175CD3"
        />
        <path
          d="M32 46.3332C24.0933 46.3332 17.6666 39.9065 17.6666 31.9998C17.6666 24.0932 24.0933 17.6665 32 17.6665C39.9066 17.6665 46.3333 24.0932 46.3333 31.9998C46.3333 39.9065 39.9066 46.3332 32 46.3332ZM32 19.6665C25.2 19.6665 19.6666 25.1998 19.6666 31.9998C19.6666 38.7998 25.2 44.3332 32 44.3332C38.8 44.3332 44.3333 38.7998 44.3333 31.9998C44.3333 25.1998 38.8 19.6665 32 19.6665Z"
          fill="#175CD3"
        />
      </svg>
    </div>
  );
};

export default DollarIcon;
