import React from "react";
import BrandLogo from "./BrandLogo";
import { NavLink } from "react-router-dom";

const Navbar = ({ lightNav = false }) => {
  return (
    <header
      className={`py-7 border-none ${
        lightNav ? "bg-white !text-black" : "bg-black text-white"
      }`}
    >
      <div className="container flex items-center justify-between ">
        <div className=" max-w-sm">
          <NavLink to="/">
            <BrandLogo light={lightNav} />
          </NavLink>
        </div>
        <nav className="hidden sm:block">
          <ul className="flex gap-4 items-center">
            <li className=" font-medium">
              <NavLink to="/about">About</NavLink>
            </li>
            <li className="font-medium">
              <a href="#faq">FAQ</a>
            </li>
            <button
              className={`${
                lightNav ? "bg-[#5D3FD3] text-white" : "bg-white text-black "
              } px-4 py-2 rounded-xl font-medium`}
            >
              Get Started
            </button>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Navbar;
