import React from "react";
import Banner from "../components/Banner";
import image from "../assets/stantpay-image.png";
import FaqSection from "../components/FaqSection";

const About = () => {
  return (
    <div className="max-w-[1580px] px-1 sm:px-10 mx-auto">
      <div className="hero-sectio_text my-10 md:my-28 md:w-[35rem] mx-auto">
        <p className="text-[28px] md:text-[32px] font-semibold text-center">
          Making Crypto Payments Simple and Accessible for Everyone
        </p>
      </div>
      <div className="container md:flex items-center justify-around !my-14 md:!my-20 sm:px-20 gap-20 ">
        <div className="hidden lg:block md:w-1/3">
          <img src={image} alt="" />
        </div>
        <div className="lg:w-2/3 leading-10 text-[16px] text-black text-center lg:text-left">
          Stantpay is a crypto form bill payment platform, we help you pay Bills
          for Electricity, Tv Cables, Airtime & Data on any device, anytime and
          anywhere. Our goal is to encourage widespread cashless adoption while
          lowering the cost of convenience. At our FIN-TECH company, our mission
          is to revolutionize the way people engage with their finances by
          leveraging cutting-edge technology. Through the power of nfc, qr
          codes, user tags, and public links, we aim to make financial
          transactions and interactions faster, easier, and more secure for
          everyone.
        </div>
      </div>

      <Banner />

      <FaqSection />
    </div>
  );
};

export default About;
